import styled from "@emotion/styled"
import axios from "axios"
import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import popupImage from "../images/popup-image.jpg"
import { Button } from "./Button"
import { FormInput } from "./FormInput"
import { GridForm } from "./GridForm"
import { Heading } from "./Heading"
import { Popup } from "./Popup"
import { Text } from "./Text"
import { TextImageSection } from "./TextImageSection"
import { VisuallyHidden } from "./VisuallyHidden"

export const ContactSection = () => {
  const {
    kirby: { site },
  } = useStaticQuery(graphql`
    query {
      kirby {
        site {
          contactTitle
          contactIntro
          contactBigImage {
            url
            alt
          }
          contactSmallImage {
            url
            alt
          }
        }
      }
    }
  `)

  const [showConfirmation, setConfirmation] = useState(false)
  const [busy, setBusy] = useState(false)
  const { register, handleSubmit, reset } = useForm()

  const onSubmit = async (form) => {
    setBusy(true)

    if (form.botField) {
      setConfirmation(true)
      reset()
      return
    }

    const res = await axios.post("/.netlify/functions/contact", {
      form,
      type: "contact",
    })

    if (res.status === 200) {
      setConfirmation(true)
      reset()
    }

    setBusy(false)
  }

  return (
    <ContactSectionWrap
      bigImgId={site.contactBigImage}
      smallImgId={site.contactSmallImage}
      heading={{
        title: site.contactTitle,
        subtitle: "Contact",
        children: (
          <div dangerouslySetInnerHTML={{ __html: site.contactIntro }} />
        ),
      }}
    >
      <GridForm name="ContactFormulier" onSubmit={handleSubmit(onSubmit)}>
        <VisuallyHidden>
          <label>
            Vul dit veld niet in als je een mens bent{" "}
            <input {...register("botField")} />
          </label>
        </VisuallyHidden>

        <FormInput label="Naam" htmlFor="name" required>
          <input
            type="text"
            id="name"
            {...register("naam", { required: true })}
          />
        </FormInput>

        <FormInput label="E-mail" htmlFor="email" required>
          <input
            type="email"
            id="email"
            {...register("email", { required: true })}
          />
        </FormInput>

        <FormInput label="Bericht" htmlFor="message" required>
          <textarea id="message" {...register("bericht", { required: true })} />
        </FormInput>

        <Button type="submit" disabled={busy}>
          Bericht versturen
        </Button>

        <Text as="small" data-column="large" size={14}>
          Velden met een * zijn verplicht
        </Text>
      </GridForm>

      {showConfirmation && (
        <Popup imageUrl={popupImage} onClose={() => setConfirmation(false)}>
          <Heading as="h3">Bericht verstuurd!</Heading>
          <p>
            Bedankt voor je bericht! Ik doe mijn best om binnen 2 werkdagen te
            antwoorden.
            {/* Bedankt voor je bericht. Op dit moment heb ik vakantie. Vanaf 28
            februari kan ik weer op je mail reageren. */}
          </p>

          <p>
            Groetjes,
            <br />
            Ilse
            <br />
            <br />
            van Schriktraining Deluxe
          </p>
        </Popup>
      )}
    </ContactSectionWrap>
  )
}

const ContactSectionWrap = styled(TextImageSection)`
  margin-top: 4rem;
`
