import styled from "@emotion/styled"
import React, { FC } from "react"
import Theme from "../lib/Theme"
import { CloudinaryImage } from "./CloudinaryImage"
import { HeadingBlock, HeadingBlockProps } from "./HeadingBlock"
import { Row } from "./Row"

export const TextImageSection: FC<{
  bigImgId: { url: string; alt: string }
  smallImgId: { url: string; alt: string }
  heading: HeadingBlockProps
}> = ({ bigImgId, smallImgId, children, heading, ...props }) => (
  <Wrap {...props}>
    <Row>
      <Inner>
        <HeadingBlock {...heading} />

        {children}

        <ImageWrap>
          <BigImage
            publicId={bigImgId.url}
            alt={bigImgId.alt}
            width={1200}
            crop="scale"
          />
          <SmallImage
            publicId={smallImgId.url}
            alt={smallImgId.alt}
            width={800}
            crop="scale"
          />
        </ImageWrap>
      </Inner>
    </Row>
  </Wrap>
)

const Wrap = styled.section`
  position: relative;
  z-index: 10;
  margin-bottom: 4rem;
`

const ImageWrap = styled.div`
  ${Theme.Mediaquery.medium.max} {
    margin-top: 2rem;
    display: grid;
    gap: 0.25rem;
    grid-template-columns: repeat(2, 1fr);
    height: 10rem;
  }
`

const BigImage = styled(CloudinaryImage)`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;

  ${Theme.Mediaquery.medium.min} {
    background: #fff;
    position: absolute !important;
    top: -2rem;
    right: 0;
    width: 50%;
    height: 100%;
    border-radius: 2px;
  }
`

const SmallImage = styled(CloudinaryImage)`
  display: block;
  border-radius: 2px;
  object-fit: cover;
  width: 100%;
  height: 100%;

  ${Theme.Mediaquery.medium.min} {
    background: #fff;
    position: absolute !important;
    bottom: 0;
    left: 50%;
    margin-left: -2rem;
    margin-top: 0;
    width: 26rem;
    height: 18rem;
    border: 0.5rem solid #fff;
  }
`

const Inner = styled.div`
  padding: 4rem 0;

  ${Theme.Mediaquery.medium.min} {
    width: 40%;
  }
`
